
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: {
    TmModal,
  },
  props: {
    number: {
      type: String,
    },
  },
})
